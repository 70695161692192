/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import HeroSection from '../page-components/News/HeroSection';
import SloganSection from '../page-components/News/SloganSection';
import NewsSection from '../page-components/News/NewsSection';

// Style
import '../page-styles/News.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function News() {
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */

  return (
    <PageLayout>
      <Seo title="News" />
      <div className="news">
        <HeroSection />
        <SloganSection />
        <NewsSection />
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["News", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default News;
