/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18next } from 'gatsby-plugin-react-i18next';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import axios from 'axios';
import SingleNewsPreview from '../../../shared/UIKit/SingleNewsPreview';
import Button from '../../../shared/UIKit/Button';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors
// Local UI components
// Helpers & utils
// @TODO: Import any helper or service here
// APIS
import { API_NEWS, API_UPLOADS } from '../../../shared/ApIs';
// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function NewsSection() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  //  localization
  const { t } = useTranslation();
  const { buttonValue, btnLoad } = t('News', { returnObjects: true });
  const { language } = useI18next();

  const locale = language === undefined ? 'fr' : language;

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */
  // State
  const [newsList, setNewsList] = useState();
  // Effect
  async function getProjects() {
    const { data } = await axios(API_NEWS(locale));
    setNewsList(data);
  }
  useEffect(() => {
    getProjects();
  }, []);
  /* ***************************** RENDER HELPERS ***************************** */
  const Newsmap = newsList?.data?.slice(1).map((news) => {
    const {
      id,
      attributes: { title, shortDescription, image },
    } = news;
    return (
      <Col lg={4} sm={12} xs={12} align="center" justify="start">
        <SingleNewsPreview
          id={id}
          title={title}
          description={shortDescription}
          buttonValue={buttonValue}
          PreviewImage={API_UPLOADS(image?.data?.attributes?.url)}
        />
      </Col>
    );
  });
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="news-section" fluid style={{ marginTop: '5rem' }}>
      <Row justify="start">{Newsmap}</Row>
      <Row align="center" justify="center" style={{ margin: '3rem 0' }}>
        <Button text={btnLoad} type="button" />
      </Row>
    </Container>
  );
}

NewsSection.propTypes = {};

NewsSection.defaultProps = {};

export default NewsSection;
