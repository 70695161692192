/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './index.scss';
import { navigate } from 'gatsby';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Button({ text, type, navigationLink }) {
  /* -------------------------------- CONSTANTS ------------------------------- */
  let classNameByType = 'primary-button';

  if (type === 'primary') {
    classNameByType = 'primary-button';
  } else if (type === 'secondary') {
    classNameByType = 'secondary-button';
  }
  /* ******************************** CALLBACKS ******************************* */
  function handleNavigate() {
    navigate(navigationLink);
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <button
      type="submit"
      className={`base-button ${classNameByType}`}
      onClick={navigationLink !== undefined ? handleNavigate : undefined}
    >
      {text}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  navigationLink: PropTypes.string,
};
Button.defaultProps = {
  type: 'primary',
  navigationLink: undefined,
};

export default Button;
