/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

import { useI18next } from 'gatsby-plugin-react-i18next';
// Local UI components

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
import axios from 'axios';
import { API_NEWS, API_UPLOADS } from '../../../shared/ApIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SloganSection() {
  const { language } = useI18next();

  const locale = language === undefined ? 'fr' : language;

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */
  // State
  const [lastNew, setLastNew] = useState();
  // Effect
  async function getProjects() {
    const { data } = await axios(API_NEWS(locale));
    setLastNew(data);
  }
  useEffect(() => {
    getProjects();
  }, []);
  /* ***************************** RENDER HELPERS ***************************** */
  const News = lastNew?.data?.slice(0, 1).map((news) => {
    const {
      id,
      attributes: { title, shortDescription, image },
    } = news;
    return (
      <Row align="center" justify="center" key={id}>
        <Col lg={6} xs={12} sm={12}>
          <img
            src={API_UPLOADS(image?.data?.attributes?.url)}
            alt="news-slogan"
          />
        </Col>
        <Col className="slogan-section-description" lg={6} xs={12} sm={12}>
          <h2>{title}</h2>
          <p>{shortDescription}</p>
          <Link className="link" to={`/news-details/${id}`}>
            {language === 'fr' ? 'Lire aussi' : 'Read More'}
          </Link>
        </Col>
      </Row>
    );
  });
  return (
    <Container className="slogan-section" style={{ marginTop: '3rem' }}>
      {News}
    </Container>
  );
}

SloganSection.propTypes = {};

SloganSection.defaultProps = {};

export default SloganSection;
